<template>
    <div class="journal">
        <header>
            <div class="columns">
                <form-dropdown
                    id="acted_by"
                    name="acted_by"
                    :value="state.acted_by"
                    :options="user_as_options"
                    :empty-label="translate('All Authors')"
                    :required="false"
                    class="column col-auto"
                    @input="filter_by('acted_by', $event)"
                />
                <form-dropdown
                    id="filter"
                    name="filter"
                    :value="state.filter"
                    :options="filters"
                    :empty-label="translate('All Categories')"
                    :required="false"
                    class="column col-auto"
                    @input="filter('category_id', $event)"
                />
                <form-dropdown
                    id="action_id"
                    name="action_id"
                    :value="state.action_id"
                    :options="action_ids"
                    :empty-value="0"
                    :empty-label="translate('All Types')"
                    :required="false"
                    @input="filter('action_id', $event)"
                />
                <form-input
                    id="search"
                    name="search"
                    v-model="query"
                    glyph="search"
                    glyph-position="left"
                    :live="true"
                    :required="false"
                    class="column"
                />
                <div
                    v-if="!!export_url"
                    class="column col-auto"
                >
                    <base-link
                        :href="export_url"
                        class="btn btn-link"
                    >
                        <open-icon glyph="file-csv" size="lg" :title="translate('Export')" />
                    </base-link>
                </div>
                <div
                    v-if="!topicEntity || (topicEntity !== 'App\\Person')"
                    class="column col-auto"
                >
                    <div class="btn-group">
                        <default-button
                            v-for="topic in topics"
                            :key="topic.id"
                            :active="state.topic === topic.id"
                            @click.prevent.stop="filter_by('topic', topic.id)"
                        >
                            {{ topic.name }}
                        </default-button>
                    </div>
                </div>
            </div>
            <journal-entry-add-button
                :topic-entity="topicEntity"
                :topic-entity-id="topicEntityId"
                :picked-entity="(topicEntity === 'App\\Property') ? 'App\\Person' : ''"
                :people="people"
                @click="edited_record = $event"
            />
        </header>
        <div class="columns">
            <div
                v-if="!!state.total || !!filtered"
                class="column journal-entries-list"
            >
               <ul>
                    <li
                        v-if="!filtered_rows.length"
                        class="bg-gray px-3 py-2 text-center text-italics"
                    >
                        ({{ translate("I got nothin'...") }})
                    </li>
                    <journal-entry
                        v-for="entry in filtered_rows"
                        :key="entry.id"
                        :topic-entity="topicEntity"
                        :topic-entity-id="topicEntityId"
                        :entry="entry"
                        :selected="!!edited_record && (entry.id === edited_record.id)"
                        @click="edited_record = $event"
                    />
                </ul>
            </div>
            <div v-if="!!edited_record" class="column journal-current-entry-view">
                <journal-entry-editor
                    :entry="edited_record"
                    @saved="saved_entry($event)"
                    @created="saved_entry($event, true)"
                    @deleted="edit_default_entry"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"
import debounce from "lodash/debounce"

import { is_remote_data_table_source } from "@/nibnut/mixins"
import { picks_users } from "@/custom/mixins"

import {
    FormDropdown,
    FormInput,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import JournalEntry from "./JournalEntry"
import JournalEntryEditor from "./JournalEntryEditor"
import JournalEntryAddButton from "./JournalEntryAddButton"

export default {
    name: "Journal",
    mixins: [is_remote_data_table_source, picks_users],
    components: {
        FormDropdown,
        FormInput,
        BaseLink,
        DefaultButton,
        OpenIcon,
        JournalEntry,
        JournalEntryEditor,
        JournalEntryAddButton
    },
    watch: {
        search: "update_query",
        query: "debounce_query",
        $route: "update_query"
    },
    methods: {
        pre_load () {
            this.set_state_value("computed_property_id", this.propertyId)
            this.confirming_deletion = false
            if((this.topicEntity !== this.state.topic_entity) || (this.topicEntityId !== this.state.topic_id)) {
                this.set_state_values({
                    topic_entity: this.topicEntity,
                    topic_id: this.topicEntityId
                })
            }
        },
        edit_default_entry () {
            const entries = this.entity_records(this.entity, this.current_records_ids)
            this.edited_record = entries.length ? entries[0] : null
        },
        post_load () {
            if(!this.state.total) this.new_entry()
            else if(!this.edited_record || !this.current_records_ids.find(id => id === this.edited_record.id)) this.edit_default_entry()
        },
        reset () {
            this.load_user_as_options()
            this.reset_state()
            this.refresh()
        },
        sort_rows (rows) {
            return orderBy(rows, ["pinned_until", "acted_at"], ["desc", "desc"])
        },
        filter_rows (rows) {
            if(this.state.computed_property_id) return rows.filter(row => row.computed_property_id === this.state.computed_property_id)
            return rows
        },
        update_query () {
            if(this.query !== this.state.search) this.query = this.state.search
        },
        debounce_query: debounce(function () {
            this.search(this.query)
        }, 500),
        new_entry () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                record.topic_type = this.topicEntity
                record.topic_id = this.topicEntityId
                this.edited_record = record
            }).catch(this.receive_error)
        },
        saved_entry (entry, is_new_record = false) {
            if(!entry) this.edit_default_entry()
            else {
                if(!this.edited_record.id && is_new_record) this.edited_record = entry
                const edited_record_id = this.edited_record.id
                this.refresh().then(() => {
                    this.edited_record = this.entity_record(this.entity, edited_record_id)
                    console.log({ edited_record_id })
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return `journal-${this.topicEntity}-${this.topicEntityId}`
        },
        can_refresh () {
            return !!this.profile_id && !!this.topicEntity && !!this.topicEntityId
        },
        rows () {
            let additional_rows = [...this.additionalRows]
            const filtered_by_author = this.state.acted_by ? this.user_as_options.find(user => user.id === this.state.acted_by) : false
            const filtered_by_search = (!!this.state.search && (this.state.search.length >= 3))
            if(filtered_by_author || filtered_by_search) {
                const search = filtered_by_search ? this.state.search.toLowerCase() : ""
                additional_rows = additional_rows.filter(row => {
                    return (
                        (
                            filtered_by_author &&
                            (filtered_by_author.name.toLowerCase() === row.computed_author_name.toLowerCase())
                        ) || (
                            filtered_by_search &&
                            (
                                (row.title.toLowerCase().indexOf(search) >= 0) ||
                                (row.description.toLowerCase().indexOf(search) >= 0)
                            )
                        )
                    )
                })
            }
            return [
                ...this.entity_records(this.entity, this.current_records_ids),
                ...additional_rows
            ]
        },
        fields () {
            return ["fieldset::default"]
        },
        filters () {
            const vocabulary = this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id
            return orderBy(
                this.entity_records("term").filter(term => term.vocabulary === vocabulary),
                "name",
                "asc"
            )
        },
        action_ids () {
            const note_action_id = this.constants("actions", "MANUAL_NOTE").id
            return [
                { id: 0 - note_action_id, name: this.translate("Actions") },
                { id: note_action_id, name: this.translate("Notes") }
            ]
        },
        topics () {
            return [
                { id: "App\\Person", name: this.translate("Contact") },
                { id: "App\\Property", name: this.translate("File") },
                { id: null, name: this.translate("All") }
            ]
        },
        export_url () {
            const url = new URL(`https://${window.document.location.hostname}/export/${this.entity}/txt`)

            const search = []
            const state = {
                ...this.state,
                ...{
                    sort_by: ["acted_at"],
                    sort_dir: ["desc"]
                }
            }
            Object.keys(state).forEach((key) => {
                if((!key.match(/^(?:per_?)page$/i)) && state[key]) {
                    let value = state[key]
                    if(typeof value !== "string") value = JSON.stringify(value)
                    search.push(`${key}=${encodeURIComponent(value)}`)
                }
            })

            url.search = search.join("&")
            return url.href
        }
    },
    props: {
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        additionalRows: {
            type: Array,
            default () {
                return []
            }
        },
        people: {
            type: Array,
            default () {
                return []
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "action_log",
            relation_ids: ["term", "action_log_part"],
            columns: {
                acted_at: { label: "Date", sort: "desc", type: "amount" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["pinned_until", "acted_at"],
                sort_dir: ["asc", "desc"],
                filter_on: "category_id",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                acted_by: 0,
                topic: null,
                topic_entity: null,
                topic_id: 0,
                computed_property_id: 0,
                action_id: 0
            },
            query: "",
            edited_record: null,
            confirming_deletion: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.journal {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > header {
        flex: 0 0 auto;
        position: sticky;
        top: 0;
        background: white;
        padding: $unit-4 0;
        margin-bottom: $unit-6;
        z-index: 5;
    }
    & > .columns {
        flex: 0 1 100%;
        overflow: hidden;

        .column.journal-entries-list,
        .column.journal-current-entry-view {
            overflow: auto;
            max-height: 100%;
        }
        .column.journal-entries-list {
            max-width: 50%;
            ul {
                margin: 0;
                & > li {
                    margin: 0;
                }
            }
        }
        .column.journal-current-entry-view {
            .journal-entry-editor > .columns {
                position: sticky;
                top: 0px;
                z-index: 1;
            }
            ul, ol {
                li > p {
                    display: inline;
                }
            }
        }
    }
}
</style>
