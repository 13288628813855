<template>
    <div class="journal-entry-add-button-container">
        <div class="btn-group">
            <default-button
                size="sm"
                tabindex="0"
                @click.prevent.stop="add"
            >
                {{ translate("New File Note") }}
            </default-button>
            <div
                v-if="!!people.length"
                ref="picker"
                :class="{ active: picking }"
                class="dropdown"
            >
                <default-button
                    size="sm"
                    tabindex="0"
                    class="dropdown-toggle hover-disabled"
                    @click.prevent.stop="toggle_picker"
                >
                    <open-icon glyph="caret-down" />
                </default-button>
                <ul class="menu">
                    <li class="divider" data-content="New Contact Note for:">
                    <li
                        v-for="person in sorted_people"
                        :key="person.id"
                        class="menu-item"
                    >
                        <base-link
                            href="#"
                            @click.prevent.stop="pick(person)"
                        >
                            {{ person.name }}
                        </base-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { profile_utilities } from "@/nibnut/mixins"

import {
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "JournalEntryAddButton",
    mixins: [profile_utilities],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        autoclose_picker (event) {
            if(this.picking && event && event.target && this.$refs && this.$refs.picker && !this.$refs.picker.contains(event.target)) this.toggle_picker()
        },
        toggle_picker () {
            if(!this.picking) {
                document.addEventListener("click", this.autoclose_picker)
                this.picked_id = 0
                this.picking = true
            } else {
                document.removeEventListener("click", this.autoclose_picker)
                this.picking = false
            }
        },
        pick (person) {
            this.picked_id = person.id
            this.add()
        },
        add () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                if(this.picked_id) {
                    record.topic_type = this.pickedEntity
                    record.topic_id = this.picked_id
                    this.picked_id = 0
                } else {
                    record.topic_type = this.topicEntity
                    record.topic_id = this.topicEntityId
                }
                this.$emit("click", record)
                if(this.picking) this.toggle_picker()
            }).catch(this.receive_error)
        }
    },
    computed: {
        sorted_people () {
            return orderBy(this.people, "name", "asc")
        }
    },
    props: {
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        },
        pickedEntity: {
            type: String,
            default: "Appp\\Person"
        },
        people: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            entity: "action_log",
            picked_id: 0,
            picking: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.journal-entry-add-button-container {
    display: flex;
    justify-content: center;
    & > .btn-group {
        flex: 0 0 auto;
    }
}
</style>
