<template>
    <modal-dialog
        :id="modal_id"
        :show.sync="shown"
        class="journal-dialog modal-lg"
    >
        <template v-slot:title>
            <span class="h5">{{ translate("Journal for {name}", { name: topicName || topic.name }) }}</span>
        </template>

        <journal
            :topic-entity="`App\\${topicClass}`"
            :topic-entity-id="topic.id"
            :property-id="topic.property_id || topic.id"
            :additional-rows="outreaches"
            :people="people"
            :editable="!!topic && (!topic.id || (topic.author_id === profile.id) || is_at_least_administrator)"
        />
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"

// import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
// import addl_string_utilities from "@/custom/mixins/AddlStringUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import Journal from "@/custom/components/Journal"

export default {
    name: "JournalDialog",
    mixins: [addl_profile_utilities],
    components: {
        ModalDialog,
        Journal
    },
    computed: {
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        modal_id () {
            return `${this.topicClass}-journal-dialog`
        },
        outreaches () {
            if(!this.topic) return []
            const outreach_types = Object.values(this.constants("outreach_types"))
            const outreach_field = (this.topicClass === "Property") ? "computed_property_id" : "person_id"
            return this.entity_records("outreach").filter(outreach => outreach[outreach_field] === this.topic.id).map(outreach => {
                let description = `Outreach - ${outreach_types.find(outreach_type => outreach_type.id === outreach.type).name}`
                if(outreach.route) description += ` (${outreach.route})`
                const title = description
                if(outreach.notes) description += `<br /><strong>Notes</strong><br />${outreach.notes}`
                description += `<br /><strong>Reached?</strong> ${outreach.reached ? "Yes" : "No"}`
                description += `<br /><span class="text-small">${this.nibnut_filter("nibnut.date", [outreach.executed_on])}&nbsp;@&nbsp;${this.nibnut_filter("nibnut.date", [outreach.executed_on, "hh:mm A"])}&nbsp;&bull;&nbsp;${outreach.computed_executioner_name}</span>`
                return {
                    id: outreach.id,
                    title,
                    description,
                    acted_at: outreach.executed_on,
                    computed_actor_name: outreach.computed_executioner_name,
                    computed_property_id: outreach.computed_property_id,
                    pinned_forever: false,
                    pinned_until: "",
                    read_only: true
                }
            })
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        topicName: {
            type: String,
            default: ""
        },
        topicClass: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        people: {
            type: Array,
            default () {
                return []
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.modal.journal-dialog.modal-lg,
.modal.notepad-dialog.modal-lg {
    position: fixed;
    z-index: $zindex-4 + 1;
    font-size: initial;
    font-weight: initial;

    & > .modal-container {
        width: 95vw;
        height: 95vh;
        max-width: none;
        max-height: none;

        & > .modal-body {
            padding-top: 0;
            margin-top: $unit-4;
            overflow-y: hidden;

            .journal-master-detail {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                margin-left: -$layout-spacing;
                margin-right: -$layout-spacing;

                & > .journal-master,
                & > .journal-detail {
                    flex: 1 1 50%;
                    max-width: 100%;
                    min-height: 100%;
                    padding-left: $layout-spacing;
                    padding-right: $layout-spacing;
                    transition: all 0.3s ease-in-out;
                }
                & > .journal-master {
                    .empty {
                        background: white;
                    }
                    .table {
                        table-layout:fixed;
                        td {
                            padding: 0;
                            .tile {
                                padding: $unit-2;
                                .tile-title {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .tile-subtitle {
                                    color: $gray-color;

                                    .chip {
                                        line-height: normal;
                                        height: auto;
                                        padding: 0.1rem 0.4rem;
                                    }
                                }
                                .tile-content {
                                    .btn.btn-sm {
                                        height: auto;
                                        padding-top: 0;
                                        padding-bottom: 0;
                                        line-height: normal;
                                        font-size: inherit;
                                    }
                                }
                                .tile-action {
                                    .btn-normal {
                                        i.la-thumbtack {
                                            transition: transform 0.3s ease-in-out
                                        }
                                        &.btn-success {
                                            i.la-thumbtack {
                                                transform: rotate(-45deg);
                                            }
                                        }
                                    }
                                    .popover-container {
                                        width: auto;
                                    }
                                }
                                &.active {
                                    position: relative;
                                    background: $bg-color;
                                    &::after {
                                        content: " ";
                                        position: absolute;
                                        top: 0;
                                        left: 100%;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 1.6rem 0 1.6rem 0.6rem;
                                        border-color: transparent transparent transparent $bg-color;
                                    }

                                    .tile-subtitle {
                                        color: $dark-color;

                                        .chip {
                                            background: $dark-color;
                                            color: $light-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                & > .journal-detail {
                    white-space: nowrap;

                    & > div {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .form-textbox.full-height {
                            height: auto;

                            & > .form-label {
                                padding-top: 0;
                            }
                        }
                    }
                }
                &.active {
                    & > .journal-detail {
                        flex: 0 1 0;
                        width: 0;
                        padding: 0;
                        overflow: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }
}
#property-notes-dialog.modal.modal-lg .modal-container {
    max-width: 95vw;
    max-height: 95vh;
    & > .modal-body {
        padding-top: 0;
        margin-top: $unit-4;
    }
}
</style>
