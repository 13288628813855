<template>
    <div>
        <div class="btn-group">
            <base-link
                :href="record[field] || '#'"
                target="_blank"
                :class="{ disabled: !record[field], [`btn-${size}`]: true }"
                class="btn btn-link"
                @click.stop
            >
                <slot></slot>
                <open-icon :glyph="record[field] ? 'external-link-square-alt' : 'ban'" />
            </base-link>
            <default-button
                flavor="link"
                :size="size"
                @click.prevent.stop="editing = true"
            >
                <open-icon glyph="pencil-alt" />
            </default-button>
        </div>
        <modal-dialog
            v-if="record && record.id"
            :id="`${id}-${field}-editor-dialog`"
            :show.sync="editing"
            :dismissable="true"
        >
            <template v-slot:title>
                <span class="h5">
                    <slot name="title"></slot>
                </span>
            </template>

            <form-input
                :id="`${id}-${field}-url`"
                :name="field"
                :value="record[field]"
                :required="false"
                :editable="true"
                :saving="saving"
                :error="error"
                @input="$emit('input', $event, field)"
            />
        </modal-dialog>
    </div>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "GoogleDriveUrlButton",
    components: {
        ModalDialog,
        FormInput,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
    },
    props: {
        id: {
            type: String,
            required: true
        },
        record: {
            type: Object,
            required: true
        },
        field: {
            type: String,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            editing: false
        }
    }
}
</script>
