<template>
    <li
        v-if="!!entry"
        :class="{ active: selected, disabled: !!entry.read_only }"
        class="card card-journal-entry"
    >
        <!-- **** "entry" is either an ActionLog (ideal) or a Note (temporarily) // -->
        <div
            v-if="(entry.topic_type !== topicEntity) && (entry.topic_type === 'App\\Person')"
            :style="custom_color_style('#91c440', ['borderBottomColor', '-15backgroundColor'])"
            class="card-header"
            @click.prevent.stop="$emit('click', entry)"
        >
            <div
                :style="custom_color_style('#91c440', ['borderColor'])"
                class="card-title h6"
            >
                {{ entry.title }}
                <default-button
                    v-if="entry.topic_type === 'App\\Person'"
                    flavor="link"
                    color="chameleon"
                    size="xs"
                    @click.stop="$emit('view', entry.topic_id)"
                >
                    <open-icon glyph="arrow-circle-right" />
                </default-button>
            </div>
        </div>
        <div
            class="card-body"
            @click.prevent.stop="$emit('click', entry)"
        >
            <div>{{ entry_preview(entry) }}</div>
        </div>
        <div
            @click.prevent.stop="$emit('click', entry)"
            class="card-footer"
        >
            <div class="columns">
                <div class="column">
                    {{ entry.acted_at | nibnut.date }}&nbsp;@&nbsp;{{ entry.acted_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ entry.computed_actor_name }}
                    <span v-if="!!entry.category_ids && !!entry.category_ids.length">
                        &nbsp;&bull;&nbsp;
                        <term-chip
                            v-for="category_id in entry.category_ids"
                            :key="category_id"
                            :term="term_by_id(category_id)"
                            class="chip-sm"
                        />
                    </span>
                </div>
                <div v-if="!!entry.pinned_until && !entry.read_only" class="column col-auto">
                    <default-button
                        v-if="entry.pinned_forever"
                        flavor="link"
                        size="xs"
                        @click.prevent.stop="change_pin"
                    >
                        {{ translate("pinned forever") }}
                    </default-button>
                    <default-button
                        v-else
                        flavor="link"
                        size="xs"
                        @click.prevent.stop="change_pin"
                    >
                        {{ translate("pinned until") }} {{ entry.pinned_until | nibnut.date("MM/DD/YYYY") }}
                    </default-button>
                </div>
                <div v-if="!entry.read_only" class="column col-auto">
                    <div
                        :class="{ popover: (pinning === 'quick'), 'popover-bottom':  (pinning === 'quick'), active:  (pinning === 'quick') }"
                    >
                        <default-button
                            :flavor="!!entry.pinned_until ? 'normal' : 'link'"
                            :color="!!entry.pinned_until ? 'success' : 'regular'"
                            size="xs"
                            @click.prevent.stop="toggle_quick_pin"
                        >
                            <open-icon glyph="thumbtack" />
                        </default-button>
                        <div
                            v-if="(pinning === 'quick')"
                            class="popover-container"
                        >
                            <ul
                                v-if="!pinning_custom_date"
                                class="menu"
                            >
                                <li
                                    v-for="quick_pin in quick_pins"
                                    :key="quick_pin.value"
                                    class="menu-item"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="pin_entry(quick_pin.value)"
                                    >
                                        {{ quick_pin.label }}
                                    </a>
                                </li>
                            </ul>
                            <div
                                v-else
                                class="card"
                            >
                                <div class="card-header">
                                    <div class="card-title h6 text-gray">{{ translate("Pin Until") }}</div>
                                </div>
                                <div class="card-body">
                                    <base-calendar
                                        :min="$dayjs()"
                                        :selection="pin_date"
                                        size="sm"
                                        @click="pin_entry($event)"
                                    />
                                </div>
                                <div class="card-footer">
                                    <default-button
                                        flavor="link"
                                        size="sm"
                                        @click.prevent.stop="pinning = false"
                                    >
                                        <open-icon glyph="arrow-left" /> {{ translate("Back") }}
                                    </default-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-pointer"></div>
        </div>
    </li>
</template>

<script>
import { mapGetters } from "vuex"

import { handles_saving, ui_utilities, misc_utilities } from "@/nibnut/mixins"

import {
    BaseCalendar,
    // FormTagInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    TermChip
} from "@/custom/components"

export default {
    name: "JournalEntry",
    mixins: [handles_saving, ui_utilities, misc_utilities],
    components: {
        BaseCalendar,
        // FormTagInput,
        DefaultButton,
        OpenIcon,
        TermChip
    },
    watch: {
        pinning: "maybe_reset_pinning_custom_date"
    },
    methods: {
        term_by_id (id) {
            return this.entity_record("term", id) || {}
        },
        entry_preview (entry) {
            if(entry) {
                if(entry.read_only) return entry.title
                if(entry.description) return entry.description.replace(/<br\s*\/?>/ig, " ").replace(/<p[^>]*>/ig, "").replace(/<[^>]+>/g, "")
            }
            return ""
        },
        save (value, field) {
            return this.save_field_for_record_id(this.entity, this.entry.id, value, field)
        },
        maybe_reset_pinning_custom_date () {
            if(!this.pinning) this.pinning_custom_date = false
        },
        toggle_quick_pin () {
            if(this.entry.pinned_until) {
                this.pinning = false
                this.save(null, "pinned_until")
            } else this.change_pin()
        },
        change_pin () {
            if(this.pinning) this.pinning = false
            else this.pinning = "quick"
        },
        pin_entry (until) {
            if(until) this.save(until, "pinned_until").then(() => { this.pinning = false })
            else this.edit_pin()
        },
        edit_pin () {
            this.pin_date = (this.entry.pinned_until && !this.entry.pinned_forever) ? this.$dayjs(this.entry.pinned_until) : this.$dayjs()
            this.pinning_custom_date = true
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        quick_pins () {
            const today = this.$dayjs()
            return [
                { label: this.translate("Forever"), value: this.constants("MAX_DATE") },
                { label: this.translate("1 week"), value: today.add(1, "week").format("YYYY-MM-DD") },
                { label: this.translate("2 weeks"), value: today.add(2, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("3 weeks"), value: today.add(3, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("1 month"), value: today.add(1, "month").format("YYYY-MM-DD") },
                { label: this.translate("Until..."), value: null }
            ]
        }
    },
    props: {
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        },
        entry: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "action_log",
            pinning: false,
            pinning_custom_date: false,
            pin_date: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card.card-journal-entry {
    $bg-color: lighten($primary-color, 45%);

    position: relative;
    border: 0;
    border-bottom: $border-width solid $border-color-dark;

    .card-header {
        padding: 0;
        text-align: center;
        border-bottom: $border-width solid transparent;
        // background-color: lighten(blue, 48%);
        .card-title.h6 {
            position: relative;
            display: inline-block;
            top: $border-width;
            padding: 0 $layout-spacing-sm;
            font-size: 0.5rem;
            line-height: 0.6rem;
            border: $border-width solid transparent;
            border-bottom-color: white !important;
            background-color: white !important;
            border-top-right-radius: 1em;
            border-top-left-radius: 1em;
        }
    }
    .card-body {
        position: relative;
        margin-bottom: $layout-spacing-lg;

        & > div {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
        }

        &:after {
            position: absolute;
            content: '\200C';
            width: 5em;
            right: $layout-spacing-lg;
            bottom: 0;
            @include linear-gradient(right, rgba(255, 255, 255, 0) white);
        }
    }
    .card-footer:last-child {
        border-top: $border-width dotted lighten($gray-color, 10%);
        padding-top: $layout-spacing-sm;
        padding-bottom: $layout-spacing-sm;
        font-size: 0.6rem;
        line-height: 0.8rem;

        .popover.popover-bottom *:focus + .popover-container, .popover.popover-bottom.active > .popover-container {
            transform: translate(-100%, 0) scale(1);
        }
    }
    .card-pointer {
        position: absolute;
        content: " ";
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: $bg-color;
        display: none;

        &:before {
            position: absolute;
            content: '';
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5em;
            height: 100%;
            background: $bg-color;
            clip-path: polygon(100% 50%, 0 0, 0 100%);

        }
    }
    &:nth-of-type(even) {
        background-color: $gray-color-light;
        .card-header {
            .card-title.h6 {
                border-bottom-color: $gray-color-light !important;
                background-color: $gray-color-light !important;
            }
        }
        .card-body {
            &:after {
                @include linear-gradient(right, rgba(255, 255, 255, 0) $gray-color-light);
            }
        }
    }

    &.disabled {
        flex-direction: row;
        align-items: center;
        // background-color: $gray-color-light;
        font-size: 0.8em;
        .card-body {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
    }
    &.active {
        background-color: $bg-color;
        .card-header {
            .card-title.h6 {
                border-bottom-color: $bg-color !important;
                background-color: $bg-color !important;
            }
        }
        .card-body {
            &:after {
                @include linear-gradient(right, rgba(255, 255, 255, 0) $bg-color);
            }
        }
        .card-pointer {
            display: block;
        }
    }
}
</style>
