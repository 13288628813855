<template>
    <div
        v-if="!!entry"
        class="journal-entry-editor"
    >
        <div
            v-if="!!entry.id && !entry.read_only"
            :class="{ 'text-small': editing === false }"
            class="columns align-items-center journal-entry-editor-meta"
        >
            <div v-if="editing !== false" class="column col-auto">
                <div class="form-label label-sm">
                    {{ translate("Categories") }}
                </div>
            </div>
            <div v-if="editing !== false" class="column">
                <form-tag-input
                    id="category_ids"
                    name="category_ids"
                    :value="entry.category_ids"
                    data-source="term"
                    :data-source-additional-data="term_autosuggest_data"
                    :ad-hoc="true"
                    size="sm"
                    :required="false"
                    @input="save"
                    @create="add_term"
                />
            </div>
            <div v-else class="column">
                {{ entry.acted_at | nibnut.date }}&nbsp;@&nbsp;{{ entry.acted_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ entry.computed_actor_name }}
                <span v-if="!!entry.category_ids && !!entry.category_ids.length">
                    &nbsp;&bull;&nbsp;
                    <span
                        v-for="category_id in entry.category_ids"
                        :key="category_id"
                        class="chip chip-sm"
                    >
                        {{ term_name_by_id(category_id) }}
                    </span>
                </span>
            </div>
            <div class="column col-auto">
                <default-button
                    flavor="link"
                    color="error"
                    size="xs"
                    @click.prevent.stop="confirm_deletion"
                >
                    <open-icon glyph="trash" />
                </default-button>
            </div>
        </div>
        <div
            v-else-if="!entry.id && !entry.read_only"
            class="text-small text-italic journal-entry-editor-meta"
        >
            {{ new_note_title }}
        </div>
        <div class="journal-entry-view">
            <div
                v-if="!action_log_parts.length"
                v-html="htmlize(entry.description)"
            ></div>
            <journal-entry-part
                v-for="action_log_part in action_log_parts"
                :key="action_log_part.id"
                :record="action_log_part"
                :editable="editing === action_log_part.id"
                @edit="edit_part_by_id"
            />
            <journal-entry-part
                v-if="shell_action_log_part"
                :record="shell_action_log_part"
                :editable="true"
                @create="create"
                @cancel="cancel_shell_record"
            />
        </div>
        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { profile_utilities, string_utilities, handles_saving, confirms } from "@/nibnut/mixins"

import {
    FormTagInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import JournalEntryPart from "./JournalEntryPart"

const is_empty_html = html => {
    return (!html || !html.replace(/<[^>]+>/ig, ""))
}

export default {
    name: "JournalEntryEditor",
    mixins: [profile_utilities, string_utilities, handles_saving, confirms],
    components: {
        FormTagInput,
        DefaultButton,
        OpenIcon,
        JournalEntryPart
    },
    mounted () {
        this.refocus()
    },
    watch: {
        "entry.id": "refocus"
    },
    methods: {
        refocus () {
            if(!this.entry.id) {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "action_log_part" }
                ).then(shell_record => {
                    this.shell_action_log_part = shell_record
                    this.scroll_to_end()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.scroll_to_end()
            if(this.latest_entry_id || !this.entry.id) this.editing = this.entry.id ? false : 0
            this.latest_entry_id = this.entry.id
        },
        scroll_to_end () {
            setTimeout(() => {
                const node = this.$el.querySelector(".journal-entry-view").closest(".journal-current-entry-view")
                if(node) node.scrollTo({ top: node.scrollHeight, left: 0, behavior: "smooth" })
            }, 100)
        },
        term_name_by_id (id) {
            return (this.entity_record("term", id) || {}).name
        },
        edit_part_by_id (part_id) {
            if(this.editing === part_id) this.editing = false
            else this.editing = part_id
        },
        save (value, field) {
            if(this.entry) {
                if(this.entry.id) {
                    this.save_field_for_record_id(this.entity, this.entry.id, value, field).then(entry => {
                        this.$emit("saved", entry)
                    })
                } else if(value) this.create(value)
            }
        },
        create (value) {
            if(is_empty_html(value)) {
                this.cancel_shell_record()
                return
            }
            const data = { ...this.entry, content: value }
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data,
                relation_ids: ["action_log_part"]
            }).then(entry => {
                this.$emit("created", entry)
                this.shell_action_log_part = null
                this.edit_part_by_id(entry.id)
            }).catch(this.receive_error)
        },
        cancel_shell_record () {
            if(this.editing === 0) this.editing = false
            this.shell_action_log_part = null
            this.$emit("created", null)
        },
        add_term (label, field) {
            if(!label) return
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "term",
                data: {
                    vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id,
                    name: label
                }
            }).then(term => {
                const ids = [...this.entry[field]]
                ids.push(term.id)
                this.save(ids, field)
            }).catch(error => {
                this.receive_error(error)
            })
        },
        confirm_deletion () {
            if(!this.entry || !this.entry.id) return
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Note"),
                    message: this.translate("Do you really want to delete this note? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete"),
                    delete_data: {
                        entity: this.entity,
                        id: this.entry.id
                    }
                },
                "delete-entry"
            )
        },
        confirmed () {
            if((this.confirming === "delete-entry") && this.confirmation_props.delete_data) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    this.confirmation_props.delete_data
                ).then(() => {
                    this.$emit("deleted")
                    this.done_confirming()
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_record", "entity_records"]),
        term_autosuggest_data () {
            return { fields: ["id", "name"], vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id }
        },
        new_note_title () {
            if(this.entry) {
                if(this.entry.title) return this.entry.title
                if(!!this.entry.topic_type && this.entry.topic_id) {
                    const topic = this.entity_record((this.entry.topic_type === "App\\Property") ? "property" : "person", this.entry.topic_id)
                    if(!!topic && (!!topic.display_name || !!topic.name)) return this.translate("New Note for {name}", { name: topic.display_name || topic.name })
                }
            }
            return this.translate("New Note")
        },
        action_log_parts () {
            if(!this.entry) return []
            return orderBy(
                this.entity_records("action_log_part").filter(action_log_part => {
                    return action_log_part.action_log_id === this.entry.id
                }),
                "created_at",
                "asc"
            )
        }
    },
    props: {
        entry: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "action_log",
            editing: false,
            latest_entry_id: 0,
            shell_action_log_part: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.journal-entry-editor {
    padding-right: $layout-spacing-lg;
    .journal-entry-editor-meta {
        background-color: white;
        border-bottom: $border-width dotted lighten($gray-color, 10%);
        padding-bottom: $layout-spacing-sm;
        margin-bottom: $layout-spacing-sm;
        z-index: $zindex-3;
    }
    p {
        margin-bottom: 0;
        &:empty::before {
            content: '';
            display: inline-block;
        }
    }
}
</style>
