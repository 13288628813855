<template>
    <span
        v-if="!invisible && !!notes.length"
        :class="{ 'd-invisible': invisible }"
        class="pinned-notes-button"
        @click.stop.prevent
    >
        <default-button
            color="primary"
            :size="size"
            @click.stop.prevent="viewing = !viewing"
        >
            <open-icon glyph="comment" class="mr-2" />
            {{ translate("Notes") }}
        </default-button>

        <modal-dialog
            id="pinned-notes-viewer"
            :show.sync="viewing"
        >
            <template v-slot:title>
                <slot name="title"></slot>
            </template>
            <table class="table table-striped">
                <tbody>
                    <tr
                        v-for="note in notes"
                        :key="note.id"
                    >
                        <td>
                            {{ note.content }}
                            <div class="text-gray text-small mt-2">
                                {{ (note.acted_at || note.updated_at) | nibnut.date }}&nbsp;@&nbsp;{{ (note.acted_at || note.updated_at) | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ note.computed_author_name }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </modal-dialog>
    </span>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "PinnedNotes",
    mixins: [misc_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        ModalDialog: () => import("@/nibnut/components/ModalDialog/ModalDialog")
    },
    computed: {
        ...mapGetters(["entity_records"]),
        topic_type () {
            return `App\\${this.entity}`
        },
        notes () {
            if(!this.id) return []
            const topic_type = this.topic_type
            return orderBy(
                this.entity_records("action_log").filter(note => {
                    return !!note.pinned_until && (note.topic_type === topic_type) && (note.topic_id === this.id)
                }),
                "created_at",
                "desc"
            )
        }
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        invisible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            viewing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.pinned-notes-button {
    & > .btn.btn-sm {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        font-size: inherit;
        line-height: normal;
    }
}
</style>
