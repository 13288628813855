<template>
    <span
        :style="colored ? custom_color_style(term.hex_color) : null"
        class="chip"
    >
        <slot>{{ term.name }}</slot>
    </span>
</template>

<script>
import ui_utilities from "@/nibnut/mixins/UiUtilities"

export default {
    name: "TermChip",
    mixins: [ui_utilities],
    props: {
        term: {
            type: Object,
            required: true
        },
        colored: {
            type: Boolean,
            default: false
        }
    }
}
</script>
