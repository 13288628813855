<template>
    <feedback-button
        :status="status"
        :waiting="assigning"
        :dismiss-after="3000"
        :size="size"
        class="code-button"
        @click.prevent.stop="execute"
        @dismiss="status = null"
    >
        <span v-if="person.code">
            {{ person.code }}
            <open-icon glyph="clipboard" class="mr-4" />
        </span>
        <span v-else>
            {{ translate("Assign unique code...") }}
        </span>
    </feedback-button>
</template>

<script>
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "CodeButton",
    components: {
        FeedbackButton,
        OpenIcon
    },
    methods: {
        copy_code () {
            this.$copyText(this.person.code).then(() => {
                this.status = true
            }, () => {
                this.status = false
            })
        },
        execute () {
            if(this.person.code) this.copy_code()
            else {
                this.assigning = true
                this.$store.dispatch(
                    "RECORD_ACTION",
                    {
                        entity: "person",
                        id: this.person.id,
                        action: "code",
                        data: {},
                        method: "put"
                    }
                ).then(() => {
                    this.copy_code()
                }).catch(error => {
                    this.$error(error.message)
                }).finally(() => {
                    this.assigning = false
                })
            }
        }
    },
    props: {
        person: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        }
    },
    data () {
        return {
            status: null,
            assigning: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.code-button {
    max-width: 9rem;
}
</style>
