<template>
    <div id="properties-data-table">
        <data-table
            :id="id"
            :columns="visible_columns"
            :rows="filtered_rows"
            :sort-fields="state.sort_by"
            :sort-directions="state.sort_dir"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="1"
            :can-add="!state.advanced_query.length && canAdd"
            :searchable="false"
            :show-totals="false"
            :empty-title="translate('Set a filter to see a list of relevant properties')"
            :empty-add-button-title="translate('Filter...')"
            :export-url="exportable ? export_url : null"
            :compact="!!$mq.match(/^(xs|sm|md|lg|xl)$/i)"
            :selection="selected_row_ids"
            :class="{ 'non-sticky': sticky_hack }"
            class="properties-list"
            @select="select_row"
            @clear-selection="clear_selection"
            @filter-by-selection="filter_by_selection"
            @sort="sort_by"
            @page="goto_page"
            @add="set_initial_filter"
            @click="record_edit(entity, $event.uuid)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <query-builder
                        :fields="query_fields"
                        :criteria="state.advanced_query"
                        :empty-search-description="translate('No Filter Defined')"
                        :open-query-builder-request="open_query_builder_request"
                        @toggled="sticky_hack = $event"
                        @input="apply_advanced_query"
                    >
                        <default-button
                            v-if="pinnable"
                            flavor="link"
                            class="ml-4"
                            @click.prevent.stop="save_preferences(state.advanced_query, 'default_property_list_query')"
                        >
                            <open-icon glyph="thumbtack" />
                        </default-button>
                    </query-builder>
                    <div v-if="!!state.advanced_query.length" class="text-small text-gray">
                        <span v-if="state.found !== state.total">{{ found_caption }} / </span>{{ total_caption }}
                    </div>
                </div>
            </template>
            <template
                v-slot:buttons
            >
                <slot name="buttons"></slot>
                <div>
                    <!--
                    <form-toggle-input
                        v-if="filterableByAssignment"
                        id="my-properties"
                        name="my_properties"
                        type="checkbox"
                        :value="state.assigned_to === profile.id"
                        :required="false"
                        @input="filter_by('assigned_to', $event ? profile.id : 0)"
                    >
                        {{ translate("Assigned To Me") }}
                    </form-toggle-input>
                    <form-toggle-input
                        id="watched-properties"
                        name="watched_properties"
                        type="checkbox"
                        :value="state.watched_properties"
                        :required="false"
                        @input="filter_by('watched_properties', $event)"
                    >
                        {{ translate("Watched Only") }}
                    </form-toggle-input>
                    //-->
                    <div v-if="!!state.advanced_query.length" class="text-small text-gray mt-2">
                        <default-button
                            flavor="link"
                            size="xs"
                            :title="translate('Previous page')"
                            :disabled="state.page <= 1"
                            v-shortkey="['arrowleft']"
                            @shortkey="goto_page(state.page - 1)"
                            @click.prevent="goto_page(state.page - 1)"
                        >
                            <open-icon
                                glyph="chevron-left"
                            />
                        </default-button>
                        Page
                        <form-dropdown
                            id="current_page"
                            name="current_page"
                            :value="state.page"
                            :options="pages"
                            size="sm"
                            :required="true"
                            @input="goto_page($event)"
                        />
                        of {{ nb_pages }}
                        <default-button
                            flavor="link"
                            size="xs"
                            :title="translate('Next page')"
                            :disabled="state.page >= (nb_pages - 1)"
                            v-shortkey="['arrowright']"
                            @shortkey="goto_page(state.page + 1)"
                            @click.prevent="goto_page(state.page + 1)"
                        >
                            <open-icon
                                glyph="chevron-right"
                            />
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <div class="columns">
                    <div class="column col-6 col-sm-12">
                        <property-status-button
                            :property="row"
                            :property-address="address_one_line(address_for_row(row))"
                            @changed="refresh"
                        />
                        <entity-assignments
                            topic-class="Property"
                            :topic="row"
                            :topic-name="address_one_line(address_for_row(row))"
                            class="mt-2"
                        />
                    </div>
                    <!--
                    <div class="column col-6 col-sm-12">
                        <assignment-buttons
                            :entity="entity"
                            :topic="row"
                        />
                    </div>
                    //-->
                </div>
                <h6>
                    {{ row.computed_owner_names }}
                    <span v-if="!!row.life_estate">({{ translate("Life Estate") }})</span>
                    <span v-else-if="!!row.computed_owner_deceased">({{ translate("Deceased") }})</span>
                </h6>
                <div>{{ address_block_line(1, address_for_row(row)) }}</div>
                <div class="text-small">{{ string_concat(", ", address_for_row(row).city, address_for_row(row).state, address_for_row(row).zip) }}</div>
                <div>{{ address_for_row(row).county_name }}</div>
                <div>
                    <strong>{{ translate("Foreclosure") }}:</strong><br />
                    <span v-if="!!row.computed_foreclosure_stage">{{ constant_options("foreclosure_stages").find(type => type.id === row.computed_foreclosure_stage).name }}</span>
                    <span v-else class="text-gray">{{ translate("Unknown") }}</span>
                </div>
                <div>
                    <strong>{{ translate("Property Tax Indicator") }}:</strong><br />
                    {{ row.status_name }}
                </div>
                <div class="columns">
                    <div class="column col-6 col-sm-12">
                        <strong>{{ translate("Auction Date") }}:</strong>
                        <span v-if="!!row.auction_on">{{ row.auction_on | nibnut.date }}</span>
                        <span v-else-if="!!row.computed_tax_deed_status">{{ row.computed_tax_deed_status }}</span>
                        <span v-else class="text-gray">{{ translate("No Date") }}</span>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <strong>{{ translate("Delinquent Tax") }}:</strong>
                        <span v-if="row.tax_deed_status === constants('tax_deed_statuses', 'STATUS_TAX_DEED_SALE').id">{{ translate("T.D.") }}</span>
                        <span v-else>{{ row.computed_delinquent_tax_year_count | nibnut.number("0,0") | nibnut.or(translate("None"), !!row.computed_delinquent_tax_year_count) }}</span>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <strong>{{ translate("Mortgage Term") }}:</strong>
                        {{ translate("1 year:::{n} years", { n: row.computed_mortgage_term }, row.computed_mortgage_term) | nibnut.or(translate("Unknown"), !!row.computed_mortgage_term) }}
                    </div>
                </div>
                <table class="table mt-4">
                    <thead>
                        <tr class="text-small text-uppercase">
                            <th>{{ translate("Estimated Value") }}</th>
                            <th>{{ translate("Tax Balance") }}</th>
                            <th>{{ translate("Equity") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ row.computed_estimated_value / 100 | nibnut.currency({ precision: 0 }) }}</td>
                            <td>{{ row.computed_delinquent_tax_value / 100 | nibnut.currency({ precision: 0 }) }}</td>
                            <td>{{ row.computed_equity_value / 100 | nibnut.currency({ precision: 0 }) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'is_watched'">
                    <default-button
                        flavor="link"
                        :color="(row.is_watched) ? 'primary' : 'light'"
                        class="watch-button"
                        @click.stop
                    >
                        <open-icon glyph="bookmark" />
                    </default-button>
                </div>
                <div v-else-if="field === 'is_featured'">
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent.stop="save_field_for_record_id(entity, row.id, !!row.is_featured ? 0 : 1, 'is_featured')"
                    >
                        <open-icon v-if="!!row.is_featured" glyph="star" class="text-primary" />
                        <open-icon v-else glyph="asterisk" class="text-gray" />
                    </default-button>
                </div>
                <div
                    v-else-if="field === 'status_name'"
                >
                    <property-status-button
                        :property="row"
                        :property-address="address_one_line(address_for_row(row))"
                        @changed="refresh"
                    />
                    <entity-assignments
                        topic-class="Property"
                        :topic="row"
                        :topic-name="address_one_line(address_for_row(row))"
                        class="mt-2"
                    />
                </div>
                <span v-else-if="field === 'computed_auction_status'">
                    <span v-if="!!row.auction_on">{{ row.auction_on | nibnut.date }}</span>
                    <span v-else-if="!!row.computed_tax_deed_status">{{ row.computed_tax_deed_status }}</span>
                    <span v-else class="text-gray">{{ translate("No Date") }}</span>
                </span>
                <div v-else-if="field === 'computed_foreclosure_stage'">
                    <span v-if="!!row.computed_foreclosure_stage">{{ constant_options("foreclosure_stages").find(type => type.id === row.computed_foreclosure_stage).name }}</span>
                    <span v-else class="text-gray">{{ translate("Unknown") }}</span><br />
                </div>
                <span
                    v-else-if="field === 'computed_delinquent_tax_year_count'"
                    :class="{ 'text-gray': !row.computed_delinquent_tax_year_count && (row.tax_deed_status !== constants('tax_deed_statuses', 'STATUS_TAX_DEED_SALE').id) }"
                >
                    <span v-if="row.tax_deed_status === constants('tax_deed_statuses', 'STATUS_TAX_DEED_SALE').id">{{ translate("T.D.") }}</span>
                    <span v-else>{{ row.computed_delinquent_tax_year_count | nibnut.number("0,0") | nibnut.or(translate("None"), !!row.computed_delinquent_tax_year_count) }}</span>
                </span>
                <span v-else-if="(field === 'computed_delinquent_tax_value') || (field === 'computed_estimated_value') || (field === 'computed_equity_value')">{{ row[field] / 100 | nibnut.currency({ precision: 0 }) }}</span>
                <span
                    v-else-if="field === 'computed_mortgage_term'"
                    :class="{ 'text-gray': !row.computed_mortgage_term }"
                >
                    {{ translate("1 year:::{n} years", { n: row.computed_mortgage_term }, row.computed_mortgage_term) | nibnut.or(translate("Unknown"), !!row.computed_mortgage_term) }}
                </span>
                <span v-else-if="field === 'computed_owner_deceased'">
                    <span v-if="!!row.life_estate">{{ translate("Life Estate") }}</span>
                    <span v-else-if="!!row.computed_owner_deceased">{{ translate("Yes") }}</span>
                </span>
                <span v-else-if="field === 'address_id'">
                    {{ address_block_line(1, address_for_row(row)) }}
                    <div class="text-small">{{ string_concat(", ", address_for_row(row).city, address_for_row(row).state, address_for_row(row).zip) }}</div>
                </span>
                <div v-else-if="field === 'county_name'">
                    {{ address_for_row(row)[field] }}
                    <div v-if="is_developer" class="text-small text-gray">ATTOM #{{ row.attom_id }}</div>
                </div>
                <div
                    v-else-if="field === 'computed_last_status_change_at'"
                    :class="{ 'tooltip': !!row.computed_last_status_change_actor_name, 'text-gray': (row.computed_last_status_change_actor_name === 'Automatic') }"
                    :data-tooltip="row.computed_last_status_change_actor_name"
                >
                    <div>
                        {{ row.computed_last_status_change_at | nibnut.date("MM/DD/YY") }}<span v-if="!!row.computed_last_status_change_actor_name && (row.computed_last_status_change_actor_name !== 'Automatic')">&nbsp;<open-icon glyph="question-circle" class="text-gray" /></span>
                    </div>
                    <pinned-notes
                        entity="Property"
                        :id="row.id"
                        size="sm"
                    >
                        <template v-slot:title>
                            {{ address_block_line(1, address_for_row(row)) || address_block_line(2, address_for_row(row)) }}
                        </template>
                    </pinned-notes>
                </div>
                <span v-else-if="field === 'occupancy_status'">
                    <span v-if="row.occupancy_status === constants('occupancy_statuses', 'OCCUPANCY_OCCUPIED').id">Occ</span>
                    <span v-else-if="row.occupancy_status === constants('occupancy_statuses', 'OCCUPANCY_VACANT').id">Vac</span>
                </span>
                <!--
                <span v-else-if="(field === 'computed_last_heirship_package_sent_on') || (field === 'computed_last_conversation_on')">
                    <open-icon
                        :glyph="!!row[field] ? 'check-circle' : 'circle'"
                        size="lg"
                        :title="flag_title_for_row(row, field)"
                        :class="{ 'text-success': !!row[field], 'text-gray': !row[field] }"
                    />
                </span>
                <div v-else-if="field === '_avatar'">
                    <assignment-buttons
                        :entity="entity"
                        :topic="row"
                    />
                </div>
                //-->
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
        <new-property-dialog
            :show.sync="creating"
        />
        <div v-if="loading" class="properties-data-table-loader">
            <loader />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import crud_utilities from "@/nibnut/mixins/CrudUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

import QueryBuilder from "@/custom/dialogs/QueryBuilder"
import NewPropertyDialog from "@/custom/dialogs/NewPropertyDialog"
import PropertyStatusButton from "@/custom/components/PropertyStatusButton"
import PinnedNotes from "@/custom/components/PinnedNotes"
import EntityAssignments from "@/custom/components/EntityAssignments"
import Loader from "@/custom/components/Loader"

export default {
    name: "PropertiesDataTable",
    mixins: [is_remote_data_table_source, handles_saving, crud_utilities, string_utilities, addl_profile_utilities],
    components: {
        FormDropdown,
        DefaultButton,
        OpenIcon,
        QueryBuilder,
        NewPropertyDialog,
        PropertyStatusButton,
        PinnedNotes,
        EntityAssignments,
        Loader
    },
    watch: {
        externalCreationRequests: "start_creating",
        userId: "reset"
    },
    methods: {
        pre_load () {
            if((this.state.assigned_to < 0) && (this.isRoot || !!this.userId) && this.profile_id) {
                const state = {
                    assigned_to: this.userId,
                    advanced_query: []
                }
                if(!this.userId && this.isRoot) {
                    state.advanced_query = this.preferences("default_property_list_query") || []
                }
                this.set_state_values(state)
            }
            this.$store.dispatch(
                "AUTOSUGGEST",
                {
                    entity: "county",
                    context: "properties-list",
                    data: { query: "" }
                }
            ).then(counties => {
                this.counties = counties
            }).catch(() => [])
            this.$emit("export-url", this.export_url)
        },
        select_row (identifier, select) {
            this.$emit("select", identifier, select)
        },
        clear_selection () {
            this.$emit("clear-selection")
        },
        address_for_row (row) {
            return this.entity_record("address", row.address_id) || {}
        },
        property_status_for_row (row) {
            return this.constant_options("property_tax_statuses").find(type => type.id === row.computed_property_tax_status) || {}
        },
        status_change_log_for_row (row) {
            return this.entity_records("action_log").find(action_log => {
                return (action_log.topic_type === "App\\Property") && (action_log.topic_id === row.id)
            }) || {}
        },
        /*
        flag_title_for_row (row, field) {
            const sent_on = this.nibnut_filter("nibnut.date", [row[field]])
            if(field === "computed_last_heirship_package_sent_on") {
                if(row[field]) return this.translate("Heirship Package sent on {sent_on}", { sent_on })
                return this.translate("No Heirship Package sent yet")
            }
            if(field === "computed_last_conversation_on") {
                if(row[field]) return this.translate("Conversation on {sent_on}", { sent_on })
                return this.translate("No Conversation yet")
            }
            return null
        },
        */
        row_is_editable (row) {
            return !!this.profile_id && (this.is_at_least_administrator || !row.assignee_ids.length || (row.assignee_ids.indexOf(this.profile.id) >= 0))
        },
        save_row_date (row, date, field) {
            if(!(date instanceof Date)) date = this.$dayjs(date)
            this.save_field_for_record_id(this.entity, row.id, date.format("YYYY-MM-DD"), field)
        },
        set_initial_filter () {
            this.open_query_builder_request += 1
        },
        start_creating () {
            this.creating = true
        },
        apply_advanced_query (advanced_query) {
            this.set_state_values({
                page: 1,
                advanced_query
            })
            this.refresh()
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return this.id
        },
        can_refresh () {
            return !!this.profile_id && (!!this.userId || !!this.state.filter_on || !!this.state.filter || this.state.archived || !!this.state.search || !!this.state.advanced_query.length)
        },
        fields () {
            return ["fieldset::list", "ns::address;fieldset::property-list", "ns::action_log;fieldset::tooltip", "ns::user;fieldset::assignments"]
        },
        query_fields () {
            return { // keep in sync with Property.php apply_advanced_query
                status_id: {
                    id: "status_id",
                    label: this.translate("Status"),
                    type: "dropdown",
                    choices: this.property_statuses,
                    operators: ["=", "!="]
                },
                computed_county_id: {
                    id: "computed_county_id",
                    label: this.translate("County"),
                    type: "dropdown",
                    choices: this.counties,
                    operators: ["=", "!="]
                },
                "owners.computed_name": {
                    id: "owners.computed_name",
                    label: this.translate("Owner Name"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "*", "!*", "$"]
                },
                "address.computed_address": {
                    id: "address.computed_address",
                    label: this.translate("Address"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "~", "$"],
                    default_operator: "^"
                },
                "property_numbers.number": {
                    id: "property_numbers.number",
                    label: this.translate("Folio ID"),
                    type: "text",
                    operators: ["=", "!="]
                },
                computed_estimated_value: {
                    id: "computed_estimated_value",
                    label: this.translate("Estimated Value"),
                    type: "stripe",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                },
                computed_owner_deceased: {
                    id: "computed_owner_deceased",
                    label: this.translate("Deceased"),
                    type: "bool",
                    operators: ["T", "F"]
                },
                life_estate: {
                    id: "life_estate",
                    label: this.translate("Life Estate"),
                    type: "bool",
                    operators: ["T", "F"]
                },
                computed_delinquent_tax_year_count: {
                    id: "computed_delinquent_tax_year_count",
                    label: this.translate("Delinquent Tax Years"),
                    type: "number",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                },
                computed_delinquent_tax_value: {
                    id: "computed_delinquent_tax_value",
                    label: this.translate("Delinquent Tax Amount"),
                    type: "stripe",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                },
                computed_foreclosure_stage: {
                    id: "computed_foreclosure_stage",
                    label: this.translate("Foreclosure Indicator"),
                    type: "dropdown",
                    choices: this.constant_options("foreclosure_stages"),
                    operators: ["=", "!="]
                },
                computed_property_tax_status: {
                    id: "computed_property_tax_status",
                    label: this.translate("Proprety Tax Indicator"),
                    type: "dropdown",
                    choices: this.constant_options("property_tax_statuses"),
                    operators: ["=", "!="]
                },
                auction_on: {
                    id: "auction_on",
                    label: this.translate("Auction"),
                    type: "date",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                },
                "mortgages.": {
                    id: "mortgages.",
                    label: this.translate("Has Mortgages"),
                    type: "bool",
                    operators: ["T", "F"]
                },
                "property_liens.": {
                    id: "property_liens.",
                    label: this.translate("Has Judgement/Liens"),
                    type: "bool",
                    operators: ["T", "F"]
                },
                "foreclosures.recorded_on": {
                    id: "foreclosures.recorded_on",
                    label: this.translate("Recorded Date"),
                    type: "date",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                },
                "people.computed_name": {
                    id: "people.computed_name",
                    label: this.translate("Contact Name"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "*", "!*", "$"]
                },
                "person_addresses.computed_address": {
                    id: "person_addresses.computed_address",
                    label: this.translate("Contact Address"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "~", "$"],
                    default_operator: "^"
                },
                "contact_numbers[type=2].value": {
                    id: "contact_numbers[type=2].value",
                    label: this.translate("Contact Phone"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "*", "!*", "$"]
                },
                "contact_numbers[type=1].value": {
                    id: "contact_numbers[type=1].value",
                    label: this.translate("Contact Email"),
                    type: "text",
                    operators: ["=", "!=", "<", "<=", ">", ">=", "^", "*", "!*", "$"]
                },
                updated_at: {
                    id: "updated_at",
                    label: this.translate("Created Or Modified"),
                    type: "date",
                    operators: ["=", "!=", "<", "<=", "<>", ">", ">="]
                }
            }
        },
        found_caption () {
            return this.translate("{number} property found:::{number} properties found", { number: this.nibnut_filter("nibnut.number", [this.state.found, "0,0"]) }, this.state.found)
        },
        total_caption () {
            return this.translate("{number} property:::{number} properties", { number: this.nibnut_filter("nibnut.number", [this.state.total, "0,0"]) }, this.state.total)
        },
        pages () {
            return Array.from(Array(this.nb_pages).keys()).map(index => {
                const id = index + 1
                return { id, name: id }
            })
        },
        pinnable () {
            return !!this.state.advanced_query.length && (JSON.stringify(this.state.advanced_query) !== JSON.stringify(this.preferences("default_property_list_query") || []))
        }
    },
    props: {
        id: {
            type: String,
            default: "properties-list"
        },
        userId: {
            type: Number,
            default: 0
        },
        exportable: {
            type: Boolean,
            default: true
        },
        filterableByAssignment: {
            type: Boolean,
            default: false
        },
        externalCreationRequests: {
            type: Number,
            default: 0
        },
        canAdd: {
            type: Boolean,
            default: true
        },
        isRoot: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "property",
            relation_ids: ["address", "action_log", "user", "assignment", "note"],
            columns: {
                is_featured: { label: " ", sort: null, type: "numeric", head_class: { "col-flag-dot": true }, cell_class: { "col-flag-dot": true } },
                // is_watched: { label: " ", sort: false }, // always visible, either inactive or active
                status_name: { label: "Status", sort: "asc", type: "amount" }, // status + down arrow + (?) if has comment ; click cell opens dialog to set status and optional comment
                computed_last_status_change_at: { label: "Status Ch.", sort: null, type: "numeric", head_class: { "col-date": true }, cell_class: { "col-date": true } },
                computed_auction_status: { label: "Auction", sort: false, type: "amount", head_class: { "col-date": true }, cell_class: { "col-date": true } }, // date, can be set from list
                computed_foreclosure_stage: { label: "Forecl.", sort: false, type: "alpha" },
                computed_delinquent_tax_year_count: { label: "Delq Tax", sort: null, type: "numeric", head_class: { "col-stat-large-number": true }, cell_class: { "col-stat-large-number": true } },
                computed_tax_deed_status: { label: "Tax Status", sort: null, type: "alpha" },
                computed_delinquent_tax_value: { label: "Tax Bal.", sort: null, type: "numeric" },
                computed_mortgage_term: { label: "Mortgage", sort: null, type: "numeric" },
                computed_estimated_value: { label: "Estimated", sort: null, type: "numeric" },
                computed_equity_value: { label: "Equity", sort: "desc", type: "numeric" },
                computed_owner_deceased: { label: "Deceased", sort: false, type: "amount" },
                computed_owner_names: { label: "Owner(s)", sort: null, type: "alpha" }, // multiple names?
                address_id: { label: "Address", sort: null, type: "alpha" }, // line1, line2, (city, state) in lighter/smaller font
                county_name: { label: "County", sort: null, type: "alpha" },
                occupancy_status: { label: "Occ/Vac", sort: false, head_class: { "text-center": true }, cell_class: { "text-center": true } }
                /*
                computed_last_heirship_package_sent_on: { label: "H", sort: false, type: "alpha", head_class: { "col-flag-dot": true }, cell_class: { "col-flag-dot": true } },
                computed_last_conversation_on: { label: "C", sort: false, type: "alpha", head_class: { "col-flag-dot": true }, cell_class: { "col-flag-dot": true } }
                */
                // _avatar: { label: " ", sort: false } // assigned to ; visible only viewing "all"? (!state.my_properties) **** what happens to watched items that may have been assigned to soemone else?
            },
            default_state: {
                per_page: 50,
                page: 1,
                sort_by: ["status_id", "computed_delinquent_tax_value"],
                sort_dir: ["asc", "desc"],
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                advanced_query: [],
                assigned_to: -1,
                watched_properties: false
            },
            counties: [],
            sticky_hack: false,
            creating: false,
            open_query_builder_request: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#properties-data-table {
    position: relative;

    .properties-list {
        .data-table-controls {
            .form-group:not(:last-child) {
                margin-bottom: 0;
            }
            .btn.btn-xs {
                padding: 0.02rem;
                margin-top: -0.15em;
            }
            .text-small {
                .form-group {
                    display: inline-block;
                    select.form-select {
                        font-size: 0.6rem;
                        height: 1rem;
                        padding: 0rem 1.1rem 0rem 0.3rem;
                        line-height: normal;
                    }
                }
            }
            .export-contacts-button {
                .la-stack {
                    width: 1em;
                    height: 1.5em;
                    line-height: 1.5em;
                    vertical-align: initial;

                    .la-lg {
                        left: -2px;
                    }
                }
            }
        }
        table.table {
            table-layout: fixed;
            thead {
                th {
                    top: 88px;
                }
            }
            td {
                vertical-align: top;
                .btn.watch-button {
                    &.btn-primary {
                        padding: 0.25rem 0.275rem;
                        .las {
                            font-size: 1.33333em;
                            line-height: 0.75em;
                            vertical-align: -0.0667em;
                        }
                    }
                    &.btn-light {
                        opacity: 0.5;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .properties-data-table-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($bg-color, .9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
